export const USER_KEY = 'user-key'
export const DEFAULT_PAGE_SIZE = 20
export const COURSE_IMAGE_VARIANTS = {
  THUMBNAIL: 'thumbnail',
  BANNER: 'banner',
}

export const IMAGES_FOR_CATEGORIES = {
  ai: [
    {
      src: '/images/card-cover-images/ai-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/ai-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/ai-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/ai-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  cloud: [
    {
      src: '/images/card-cover-images/cloud-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/cloud-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/cloud-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/cloud-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  cybersecurity: [
    {
      src: '/images/card-cover-images/cybersecurity-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/cybersecurity-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/cybersecurity-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/cybersecurity-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  sales: [
    {
      src: '/images/card-cover-images/sales-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/sales-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/sales-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/sales-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  data: [
    {
      src: '/images/card-cover-images/data-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/data-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/data-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/data-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  finance: [
    {
      src: '/images/card-cover-images/finance-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/finance-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/finance-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/finance-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  'people-and-hr': [
    {
      src: '/images/card-cover-images/people-and-hr-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/people-and-hr-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/people-and-hr-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/people-and-hr-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  'green-skills': [
    {
      src: '/images/card-cover-images/greenskills-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/greenskills-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/greenskills-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/greenskills-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/greenskills-05.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  design: [
    {
      src: '/images/card-cover-images/design-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/design-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/design-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/design-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  devops: [
    {
      src: '/images/card-cover-images/devops-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/devops-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/devops-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/devops-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  'artificial-intelligence': [
    {
      src: '/images/card-cover-images/artificial-intelligence-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/artificial-intelligence-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/artificial-intelligence-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/artificial-intelligence-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  'software-engineering': [
    {
      src: '/images/card-cover-images/software-engineering-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/software-engineering-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/software-engineering-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/software-engineering-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  development: [
    {
      src: '/images/card-cover-images/development-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/development-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/development-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/development-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  'it-operations': [
    {
      src: '/images/card-cover-images/it-operations-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/it-operations-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/it-operations-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/it-operations-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  'language-learning': [
    {
      src: '/images/card-cover-images/language-learning-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/language-learning-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/language-learning-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/language-learning-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  languages: [
    {
      src: '/images/card-cover-images/languages-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/languages-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/languages-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/languages-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  leadership: [
    {
      src: '/images/card-cover-images/leadership-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/leadership-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/leadership-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/leadership-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  management: [
    {
      src: '/images/card-cover-images/management-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/management-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/management-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/management-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  marketing: [
    {
      src: '/images/card-cover-images/marketing-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/marketing-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/marketing-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/marketing-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  business: [
    {
      src: '/images/card-cover-images/business-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/business-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/business-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/business-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  'cloud-engineering': [
    {
      src: '/images/card-cover-images/cloud-engineering-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/cloud-engineering-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/cloud-engineering-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/cloud-engineering-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
  'product-management': [
    {
      src: '/images/card-cover-images/product-management-01.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/product-management-02.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/product-management-03.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
    {
      src: '/images/card-cover-images/product-management-04.jpg',
      width: 924,
      height: 420,
      variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
    },
  ],
}

export const DEFAULT_CARD_COVERS = [
  {
    src: '/images/card-cover-images/default-01.jpg',
    width: 924,
    height: 420,
    variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
  },
  {
    src: '/images/card-cover-images/default-02.jpg',
    width: 924,
    height: 420,
    variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
  },
  {
    src: '/images/card-cover-images/default-03.jpg',
    width: 924,
    height: 420,
    variant: COURSE_IMAGE_VARIANTS.THUMBNAIL,
  },
  {
    src: '/images/card-cover-images/banner-default-01.jpg',
    width: 1600,
    height: 420,
    variant: COURSE_IMAGE_VARIANTS.BANNER,
  },
  {
    src: '/images/card-cover-images/banner-default-02.jpg',
    width: 1600,
    height: 420,
    variant: COURSE_IMAGE_VARIANTS.BANNER,
  },
  {
    src: '/images/card-cover-images/banner-default-03.jpg',
    width: 1600,
    height: 420,
    variant: COURSE_IMAGE_VARIANTS.BANNER,
  },
]

export const COURSE_INFO_CATEGORY_COLORS = {
  locations: '#FCF2AD',
  styles: '#DFCDF1',
  formats: '#F7CEAC',
  type: '#C2C2C2',
}

export const COURSE_INFO_COLORS = {
  online: '#BDDEC6',
  hybrid: '#C9D4F6',
  'self-paced': '#DFCDF1',
  'full-time': '#F7CEAC',
  'part-time': '#E4E4E4',
}

export const API_URL = process.env.NEXT_PUBLIC_API_URL

export const ROUTES: Record<string, string> = {
  homepage: '/',
  login: '/login',
  authLogin: '/auth/login',
  signup: '/sign-up',
  profile: '/profile',
  courses: '/courses',
  course: '/courses/:id',
  courseRedirect: '/course-redirect',
  accessDenied: '/403',
  notFound: '/404',
  myProfile: '/my/profile',
  myProfileAddress: '/my/profile/address',
  myOrders: '/my/orders',
  myFinancing: '/my/financing',
  logout: '/auth/logout',
  kyc: '/kyc',
  simulator: '/courses/:courseId/fix-pay-simulator?cohortId=:cohortId',
  search: '/search',
  faq: '/frequent-asked-questions',
  paymentOptions: '/payment-options',
  userOnboarding: '/onboarding',
  educationProviderOnboardingStep1: '/onboarding/education-provider',
  educationProviderOnboardingStep2: '/onboarding/education-provider/legal-info',
  educationProviderOnboardingStep3:
    '/onboarding/education-provider/stripe-connect',
  landingPagePreview: '/landing-pages/:id',
  apiImageProxy: '/api/image-proxy',
}

export const PRIVATE_ROUTES: Array<string | RegExp> = [
  ROUTES.kyc,
  ROUTES.myFinancing,
  ROUTES.myOrders,
  ROUTES.myProfile,
  ROUTES.myProfileAddress,
  ROUTES.userOnboarding,
  ROUTES.educationProviderOnboardingStep1,
  ROUTES.educationProviderOnboardingStep2,
  ROUTES.educationProviderOnboardingStep3,
  ROUTES.apiImageProxy,
  /^\/landing\-pages\/.*$/,
]

export const WEBSITE_LINKS = {
  privacyPolicy: 'https://www.mydra.io/privacy-policy',
  termsAndConditions: 'https://www.mydra.io/terms-conditions',
  mydraWebsite: 'https://www.mydra.io/',
  aboutUs: 'https://www.mydra.io/about-us',
  educationProviders: 'https://www.mydra.io/education-providers',
  sevenStepAIFramework: 'https://www.mydra.io/7-step-ai-framework',
  fixPayFAQS: 'https://www.mydra.io/fixpay-faqs',
  faqs: 'https://mydra.helpscoutdocs.com',
  edpFAQs: 'https://marketplace.mydra.io/partners/edp-faqs',
  help: 'mailto:support@mydra.io?subject=Need%20Assistance%3F%20Leave%20Us%20a%20Message.',
  paymentOptions: 'https://www.mydra.io/payment-options',
}

export const EXTERNAL_LINKS = {
  courseSuggestionForm: 'https://studentfinanceteam.typeform.com/to/ogsYe8ng',
  fixPayFAQ:
    'https://studentfinance.notion.site/FixPay-FAQs-9316a36f023a4ed2b7a54cb053a21c01',
  careerCopilot: 'https://copilot.mydra.io/',
  paymentTerms:
    'https://studentfinance.notion.site/FixPay-FAQs-9316a36f023a4ed2b7a54cb053a21c01',
  linkedIn: 'https://www.linkedin.com/company/studentfinance/',
  forCompanies: 'https://www.mydra.io/for-companies',
  forEducationProviders: 'https://www.mydra.io/education-providers',
  forIndividuals: 'https://www.mydra.io/for-individuals',
  partnerDashboard: process.env.NEXT_PUBLIC_PARTNER_DASHBOARD_URL,
}

export const FIX_PAY_PRICE_BOUNDARIES = {
  min: 25000,
  max: 1000000,
}

export const FAKE_FIX_PAY_NUMBER_OF_INSTALMENTS = [12]

export const REDIRECT_TO_URL_PATH_REGEX =
  /^(?:\/?(?:en|pt|it|fr|de|es))?\/(?:[a-zA-Z0-9\-._~!$&'()*+,;=:@]+\/?)*$/

export const PATH_WITH_SUPPORTED_LOCALES_REGEX = /^\/?(en|pt|it|fr|de|es)(\/|$)/

export const USER_INFO_COOKIE_NAME = 'userInfo'
export const MAX_COURSE_FILTER_PRICE = 2500000
